import { Orderline } from '../OrderlineModel';

export class CodeRequestModel {
  ASIN!: string;
  CanImportData: boolean = false;
  CanRequestCodes!: boolean;
  CanRollback!: boolean;
  Customer!: string;
  CustomerId!: string;
  Description!: string;
  DueDate!: string;
  GTIN!: string;
  GTINType!: string;
  HasFailedRequest!: boolean;
  HasSentErrorEmail!: boolean;
  ErrorReportId!: number;
  Id!: string;
  IsSelected!: boolean;
  Options!: any[];
  OrderlineStatus!: string;
  PartNumber!: string;
  Quantity!: number;
  SKU!: string;
  HasNoCodeRequestError!: boolean;
  ProcessStatus!: string;
  IsAPIRequest!: boolean;
  HasStatusError!: boolean;
  ProcessStatusCode!: number;
  HasError!: boolean;
  OriginalOrderline!: Orderline;
  ShouldDelayProcess!: boolean;
  DelayProcessInMinutes!: number;
  public get IsMissingData() {
    return !(this.SKU && this.ASIN && this.PartNumber && this.GTIN);
  }
  constructor(rawItem?: Orderline | any) {
    this.Options = [];
    this.IsSelected = false;
    if (rawItem && (<Orderline>rawItem).Item) {
      this.PopulateFromOrderline(rawItem);
    } else {
      this.PopulateGeneric(rawItem);
    }
  }
  PopulateGeneric(rawItem?: any) {
    if (rawItem) {
      this.CanImportData = rawItem.CanImportData;
      this.CanRequestCodes = rawItem.CanRequestCodes;
      this.CanRollback = rawItem.CanRollback;
      this.Customer = rawItem.CustomerName;
      this.CustomerId = rawItem.CustomerId;
      this.Description = rawItem.ItemDesc;
      this.DueDate = rawItem.DueDate;
      this.GTIN = rawItem.Gtin;
      this.GTINType = rawItem.GtinType;
      this.HasError = rawItem.HasError;
      this.HasFailedRequest = rawItem.HasFailedRequest;
      this.HasSentErrorEmail = rawItem.HasSentErrorEmail;
      this.ErrorReportId = rawItem.ErrorReportId;
      this.Id = rawItem.Id;
      this.OrderlineStatus = rawItem.OrderlineStatus;
      this.ASIN = rawItem.Asin;
      this.PartNumber = rawItem.PartNumber;
      this.Quantity = rawItem.Quantity;
      this.SKU = rawItem.Sku;
      this.ProcessStatus = rawItem.ProcessingStatus;
      this.HasNoCodeRequestError = rawItem.HasNoCodeRequestError;
      this.IsAPIRequest = rawItem.IsAPIRequest;
      this.HasStatusError = rawItem.HasStatusError;
      this.ProcessStatusCode = rawItem.ProcessStatusCode;
    }
  }
  PopulateFromOrderline(rawItem: Orderline) {
    this.PartNumber = rawItem.Item.PartNumber;
    this.Description = rawItem.Item.Description;
    this.Quantity = rawItem.Quantity;
    this.Id = rawItem.Id;
    this.OriginalOrderline = rawItem;
  }
}

export class SubmitCodesModel {
  ConsumeId!: number;
  OrderLineId!: string;
  ShouldDelayProcess!: boolean;
  ProcessDelayInMinutes!: number;
}
